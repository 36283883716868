<template>
  <div>
    <b-card
      class="w-50 mx-auto"
    >
      <b-row
        align-v="center"
        class="mb-2"
      >
        <b-col>
          <b-link
            :to="{name: 'auth-login'}"
            class="text-secondary"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Se connecter
          </b-link>
        </b-col>
      </b-row>
      <b-card-title
        class="mb-1 font-weight-bold"
        title-tag="h2"
      >
        Création de compte
      </b-card-title>

      <validation-observer
        v-if="$route.params.token"
        ref="updatePasswordForm"
      >
        <b-form
          class="mt-2"
          @submit.prevent="createPassword"
        >
          <!-- new password -->
          <b-form-group
            label="Mot de passe"
          >

            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="recovery-password"
                  v-model="password"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="recovery-password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirmation password -->
          <b-form-group
            label="Confirmation de mot de passe"
          >

            <validation-provider
              #default="{ errors }"
              name="Confirmation"
              vid="password_confirmation"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="recovery-password-confirmation"
                  v-model="password_confirmation"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Token"
              vid="base"
            >
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <b-button
            type="submit"
            variant="primary"
            block
          >
            Envoyer
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as Sentry from "@sentry/vue";
import {
  BRow, BCol, BCard, BLink, BFormGroup, BFormInput, BForm, BButton, BInputGroupAppend, BInputGroup, BCardTitle
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/libs/acl/routeProtection";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCardTitle,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCard,
    BForm,
    BLink,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: "",
      password_confirmation: ""

    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    }
  },
  methods: {
    createPassword() {
      this.$http.userJoin(null, {
        invitation_token: this.$route.params.token,
        invitee_attributes: {
          password: this.password,
          password_confirmation: this.password_confirmation
        }
      })
        .then(response => {
          const { userData } = response.data;
          useJwt.setToken(response.data.accessToken);
          useJwt.setRefreshToken(response.data.refreshToken);
          localStorage.setItem("userData", JSON.stringify(userData));
          this.$ability.update(userData.ability);

          Sentry.configureScope(scope => scope.setUser({
            email: userData.email,
            full_name: userData.full_name,
            role: userData.roles[0]
          }));

          this.$router.replace(getHomeRouteForLoggedInUser())
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Bienvenue ${userData.full_name}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: this.toastText
                }
              });
            });
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.updatePasswordForm.setErrors(response.data.errors);
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
